<template>
  <div class="wish-item" v-if="isLoaded">
    <div class="wish-item-top">
      <div v-if="pageName==='Junior-Home' || pageName==='Junior-MyWishesDetail'" class="titleTooLong-top-1" @click="handleToWishCompany">
         {{ wish.position }}
         <img v-if="wish.isGranted" src="@/images/granted-tag.svg" class="granted-tag-icon" @mouseleave="showIconInfo = false" @mouseover="showIconInfo = true"/>
         <p :class="{'granted-tag-icon-info':true, 'info-shown':showIconInfo}">Some student(s) in this wish has/have been granted the position.</p>
      </div>
      <div v-if="pageName!=='Junior-Home' && pageName!=='Junior-MyWishesDetail'" class="titleTooLong-top-2">
        {{ wish.position }}
        <img v-if="wish.isGranted" src="@/images/granted-tag.svg" class="granted-tag-icon" @mouseleave="showIconInfo = false" @mouseover="showIconInfo = true"/>
        <p :class="{'granted-tag-icon-info':true, 'info-shown':showIconInfo}">Some student(s) in this wish has/have been granted the position.</p>
      </div>
      <p v-if="handlePage() && wish.candidateCount > 1 && !wish.isMine">
        Requested by {{ wish.lastCandidate }} and {{ wish.candidateCount - 1 }} others</p>
      <p v-if="handlePage() && wish.candidateCount === 1 && !wish.isMine">
        Requested by {{ wish.lastCandidate }}</p>
      <p v-if="handlePage() && wish.candidateCount > 1 && wish.isMine && !statusOverrule">
        Requested by You and {{ wish.candidateCount - 1 }} others</p>
      <p v-if="handlePage() && wish.candidateCount === 1 && wish.isMine && !statusOverrule">
        Requested by You</p>
      <p v-if="isForHome && timeToggle" class="time-top-right" @click="timeToggle = !timeToggle">Your Last Update:
        <strong>{{ wish.displayTime }}</strong></p>
      <p v-if="isForHome && !timeToggle" class="last-activity" @click="timeToggle = !timeToggle">Last Activity On:
        <strong>{{ wish.lastActivity }}</strong></p>
      <p v-if="statusOverrule">Last Activity On: <strong>{{ wish.lastActivity }}</strong></p>
      <p v-if="pageName==='Senior-Dashboard' || pageName==='Senior-MyAcceptedWishesDetail'">Requested by
        {{ studentProfile.firstName + " " + studentProfile.lastName }}</p>
    </div>

    <div class="wish-item-mid">
      <div class="titleTooLong-company">
        <p>{{ wish.company }}</p>
      </div>
      <div class="wish-item-location titleTooLong-location">
        <div>| {{ wish.city }}, {{ wish.state }}</div>
      </div>
      <div></div>
    </div>

    <div class="wish-item-bottom">
      <div class="wish-item-bottom-left">
        <input :id="wish.link" :value="wish.link" class="wish-item-link" disabled="disabled" type="text"/>
        <ion-icon class="small-icon" name="copy-outline" @click="copyLink"></ion-icon>
      </div>

      <div v-if="pageName==='Senior-WishCompany'" class="wish-item-senior-btn" @click="handleToWishDetail">
        View Resume(s)
      </div>

      <div v-if="pageName!=='Senior-WishCompany' && wish.isMine && status === 'pending' && wish.isSoonToExpire"
           class="wish-item-status">
        <div class="wish-item-status-icon wish-item-status-icon-message" style="background-color: #F1A540;"></div>
        <p>Inactive for 60+ Days</p>
        <div class="wish-item-status-button wish-item-status-button-yellow-reactivate" @click="handleReactivate">
          Reactivate
        </div>
      </div>

      <div v-if="pageName!=='Senior-WishCompany' && wish.isMine && (status === 'expired' || statusOverrule)"
           class="wish-item-status">
        <div class="wish-item-status-icon wish-item-status-icon-message" style="background-color: #e00031;"></div>
        <p>Inactive for 90+ Days</p>
        <div class="wish-item-status-button wish-item-status-button-red-reactivate" @click="handleReactivate">
          Reactivate
        </div>
      </div>

      <div
          v-if="pageName!=='Senior-WishCompany' && wish.isMine && status === 'pending' && !wish.isExpired && !wish.isSoonToExpire"
          class="wish-item-status">
        <div class="wish-item-status-icon"></div>
        <p>waiting for response</p>
        <div class="wish-item-status-button" @click="isManage = !isManage">Manage
          <div class="expand-collapse-arrow">
            <ion-icon
                v-show="isManage"
                class="wish-manage-icon"
                name="caret-down-outline"
            ></ion-icon>
            <ion-icon
                v-show="!isManage"
                class="wish-manage-icon"
                name="caret-back-outline"
            ></ion-icon>
          </div>
          <div v-show="isManage" class="wish-button-collapsed">
            <div class="wish-button-collapsed-edit" @click="isEdit = !isEdit">Edit</div>
            <hr/>
            <div class="wish-button-collapsed-remove" @click="handleRemoveWish">Withdraw</div>
          </div>
        </div>
        <WishEdit
            v-show="isEdit"
            :IWantThisToo="false"
            :candidateId="user.uid"
            :isDefaultResume="isDefaultResume"
            :companyId="companyId"
            :companyName="wish.company"
            :isEdit="true"
            :isNew="false"
            :wish="wish"
            @exitEdit="toggleEdit"
        />
      </div>

      <div v-if="pageName!='Senior-WishCompany' && wish.isMine && status == 'granted'" class="wish-item-status">
        <div class="wish-item-status-icon wish-item-status-icon-message" style="background-color: #359e47;"></div>
        <p>Granted</p>
        <div class="wish-item-status-button wish-item-status-button-message" @click="toMessage">Message</div>
      </div>
      <!--v-if need to be corrected-->
      <div v-if="pageName!='Senior-WishCompany' && !wish.isMine" class="wish-item-status">
        <!--if wish is active, you can click i want this too-->
        <div class="wish-item-status-button-want" @click="isEdit = !isEdit">I want this too!</div>
        <WishEdit
            v-show="isEdit"
            :IWantThisToo="true"
            :candidateId="user.uid"
            :companyId="companyId"
            :companyName="wish.company"
            :isNew="false"
            :wish="wish"
            @exitEdit="toggleEdit"
        />
      </div>
    </div>
  </div>
</template>

<script>
import WishEdit from "@/components/WishList/wishEdit";
import {useRouter} from "vue-router";
import {ref, onMounted} from "vue";
import getUser from "@/composables/getUser";
import reactivateWish from "@/composables/WishList/reactivateWish";
import removeWish from "@/composables/WishList/removeWish";
import cutMyWish from "@/composables/Profile/cutMyWish";
import getProfile from "@/composables/Profile/getProfile";

export default {
  components: {WishEdit},
  props: ["wish", "companyName", "companyId", "isForHome", "statusOverrule"],
  emits: ["change-target-chatter"],
  async setup(props, {emit}) {
    const router = useRouter();
    const {user} = getUser();
    const isEdit = ref(false);
    const isManage = ref(false);
    const IWantThisToo = ref(true);
    const status = ref(null);
    const isDefaultResume = ref(null)
    const pageName = ref(router.currentRoute.value.name);
    const studentProfile = ref(null);
    const timeToggle = ref(true);
    const showIconInfo = ref(false);
    const isLoaded = ref(false);

    onMounted(async () => {
      if (props.wish.studentId) {
        await getProfile(studentProfile, props.wish.studentId);
      }
      isLoaded.value = true;

      if (pageName.value === "Junior-WishCompany" || pageName.value === "Junior-Home" || pageName.value === "Junior-MyWishesDetail" ) {
        props.wish.candidates.forEach(candidate => {
          if (candidate.uid === user.value.uid) {
            status.value = candidate.status;
            isDefaultResume.value = candidate.isDefaultResume;
          }
        })
        if (props.statusOverrule) {
          status.value = props.statusOverrule;
        }
      } else {
        status.value = props.wish.status;
      }
    });
    

    


    const copyLink = () => {
      let textArea = document.createElement("textarea");
      textArea.value = props.wish.link;
      // make the textarea out of viewport
      textArea.style.position = "absolute";
      textArea.style.left = "0";
      textArea.style.opacity = '0';
      document.body.appendChild(textArea);
      textArea.select();
      return new Promise((res, rej) => {
        document.execCommand('copy') ? res() : rej();
        textArea.remove();
        // alert("Copied the text: " + wish.link);
      })
    }

    const toMessage = () => {
      if (pageName.value === 'Junior-Home' || pageName.value === 'Junior-MyWishesDetail' || pageName.value === 'Junior-WishCompany') {
        router.push({name: "Junior-Messages"});
      } else {
        router.push({name: "Senior-Messages"});
      }
      emit("change-target-chatter", props.wish.messageTargetId);
    };

    const toWishList = () => {
      router.push({name: "Junior-WishList"});
    };

    const toggleEdit = (isChildEdit) => {
      isEdit.value = false;
      emit('exitEdit', isChildEdit);
      //console.log("++++++++++")
    }

    const handleReactivate = async () => {
      await reactivateWish(props.companyId, props.wish.wishId, user.value.uid);
      emit('exitManage', false);
      // props.wish.status = "pending";
      // status.value = "pending";
      // props.wish.isExpired = false;
      // props.wish.isSoonToExpire = false;
    }

    const handleRemoveWish = async () => {
      //console.log("Once removed. Revert can be difficult. Please consider twice before final decision.");
      let isWholeDelete = false;
      if (props.wish.candidateCount === 1) {
        isWholeDelete = true;
      }
      await removeWish(props.companyId, props.wish.wishId, user.value.uid, props.wish.candidateCount, isWholeDelete);
      await cutMyWish(user.value.uid, props.wish.wishId);
      emit('exitManage', false);
    }

    const handleToWishCompany = () => {
      if (props.isForHome) {
        router.push({name: "Junior-WishCompany", params: {companyName: props.companyName, companyId: props.companyId}});
      }
    }

    const handleToWishDetail = (wishId) => {
      router.push({
        name: "Senior-WishDetail",
        params: {companyName: props.companyName, companyId: props.companyId, wishId: props.wish.wishId}
      });
    }

    const handlePage = () => {
      return pageName.value !== 'Junior-Home' && pageName.value !== 'Junior-MyWishesDetail' && pageName.value !== 'Senior-Dashboard' && pageName.value !== 'Senior-MyAcceptedWishesDetail'
    }

    return {
      user,
      status,
      isDefaultResume,
      timeToggle,
      IWantThisToo,
      copyLink,
      toMessage,
      toWishList,
      isEdit,
      isLoaded,
      isManage,
      toggleEdit,
      handleReactivate,
      handleRemoveWish,
      handleToWishCompany,
      pageName,
      handleToWishDetail,
      studentProfile,
      handlePage,
      showIconInfo
    }
  },
}
</script>

<style scoped>
.granted-tag-icon {
  position: absolute;
  top: 2px;
  right: -35px;
  height: 25px;
}

.granted-tag-icon-info {
  position: absolute;
  top: -55px;
  right: -223px;
  width: 190px;
  padding: 5px 7px;
  border: 1px black solid;
  background-color: white;
  color: black !important;
  font-size: 60%;
  font-weight: 400;
  z-index: 10;
  opacity: 0;
  transition: opacity 0.1s ease;
  box-shadow: 0px 1px 7px 1px rgba(0, 0, 0, 0.25);
}

.info-shown {
  opacity: 1;
  transition: opacity 0.1s ease;
}

.time-top-right {
  cursor: pointer;
  border: 0.3vh transparent solid;
  border-radius: 1vh;
  padding: 1vh;
}

.time-top-right:hover {
  border-color: var(--purple-300);
  color: var(--purple-300);
}

.last-activity {
  cursor: pointer;
  background-color: var(--purple-300);
  color: white;
  border-radius: 1vh;
  padding: 1vh;
}

.wish-item {
  background-color: white;
  border-radius: 2vh;
  padding: 1vh 3vh;
  height: 16vh;
  margin-top: 3vh;
  align-items: center;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
}

.wish-item-top {
  width: 100%;
  margin-top: 1vh;
  height: 3vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.wish-item-mid {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  height: 4vh;
  width: 100%;
  margin-bottom: 1vh;
  font-size: 1.5em;
  font-weight: bolder;
  position: relative;
}

.wish-item-location {
  position: absolute;
  left: 350px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}

.wish-item-location div {
  font-size: 20px;
}

.wish-item-location ion-icon {
  margin: 0;
  margin-left: 50px;
}

.wish-item-bottom {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
}

.wish-item-bottom-left {
  display: flex;
  width: 40%;
}

.wish-item-link {
  width: 90%;
  height: 3vh;
  overflow: scroll;
  background-color: var(--grey-50);
  border: 1px solid #DDDDDD;
  border-radius: 7px;
  line-height: 3vh;
}

.wish-item-bottom div ion-icon {
  margin-left: 2%;
  margin-right: 0;
}

.wish-item-senior-btn {
  position: absolute;
  top: -2vh;
  right: 0;
  background-color: var(--purple-300);
  color: white;
  padding: 1vh 1vw;
  border-radius: 1vh;
  transition: background-color 0.15s ease;
}

.wish-item-senior-btn:hover {
  background-color: var(--purple-400);
  cursor: pointer;
  transition: background-color 0.15s ease;
}

.wish-item-status {
  width: 60%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
}

.wish-item-status-icon {
  width: 10px;
  height: 10px;
  border: 10px;
  border-radius: 5px;
  margin-right: 1%;
  background-color: var(--purple-l);
  border-color: var(--purple-l);
}

.wish-item-status-icon-message {
  background-color: var(--teal);
}

.wish-item-status-button {
  background-color: white;
  border: 2px solid var(--purple-l);
  color: var(--purple-l);
  border-radius: 10px;
  margin-left: 5%;
  width: 120px;
  height: 4vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.wish-item-status-button-message {
  background-color: var(--teal);
  border: 0px solid var(--teal);
  color: black;
}

.wish-item-status-button-yellow-reactivate {
  background-color: var(--yellow);
  border: 0px solid var(--yellow);
  color: black;
}

.wish-item-status-button-red-reactivate {
  background-color: var(--red);
  border: 0px solid var(--red);
  color: black;
}

.wish-manage-icon {
  position: relative;
  left: 10px;
}

.wish-button-collapsed {
  position: absolute;
  width: 120px;
  padding: 5px 20px;
  top: 4.1vh;
  color: var(--purple-l);
  background-color: white;
  font-size: var(--text);
  line-height: 150%;
  box-shadow: 0px 1px 7px 1px rgba(0, 0, 0, 0.25);
  animation: fadein 0.5s;
  z-index: 10;
}

.wish-item-status-button-want {
  background-color: #fefeff;
  border: 2px solid #151515;
  border-radius: 10px;
  margin-left: 5%;
  width: 150px;
  height: 4vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wish-item-status-button-want-inactive {
  background-color: #fefeff;
  border: 2px solid #8F8F8F;
  border-radius: 10px;
  margin-left: 5%;
  width: 150px;
  height: 4vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #8F8F8F;
}

.wish-item-status-button:hover,
.wish-item-status-button-want:hover,
.wish-item-status-button-want-inactive:hover {
  cursor: pointer;
  background-color: #c27fce;
  transition-duration: 0.2s;
}

.wish-item-status-button:active {
  transition-duration: 0.2s;
}


/*Responsive Design*/
@media screen and (max-width: 768px) {
  .wish-item {
    height: 20vh;
  }

  .wish-item-top p {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
  }

  .wish-item-mid {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
    font-size: 1.4em;
  }

  .wish-item-location {
    display: none;
  }

  .wish-item-status p {
    display: none;
  }

  .wish-item-status-button {
    height: 5vh;
  }

  .wish-item-status-button {
    width: 100px;
  }

  .wish-button-collapsed {
    width: 110px;
    top: 5vh;
  }

  .time-top-right,
  .last-activity {
    display: none !important;
  }

  .wish-item-senior-btn {
    top: 0;
    right: -10px;
    padding: 0.5vh 1vw;
  }

  .wish-item-top p:nth-child(2) {
    display: none;
  }
}


/*TitleLongOrNot*/
.titleNotLong-top {
  font-size: 1em
}

.titleNotLong-mid ion-icon {
  font-size: 1.1em
}

.titleNotLong-mid div {
  font-weight: normal
}

.titleTooLong-top-1,
.titleTooLong-top-2 {
  position: relative;
  font-size: 1.3em;
  font-weight: bolder
}

.titleTooLong-top-1:hover {
  color: var(--purple-l);
  cursor: pointer;
}

.titleTooLong-company {
  font-weight: normal;
  margin-right: 10px
}

.titleTooLong-company p {
  font-size: 0.8em
}

.titleTooLong-location {
  position: static
}

.titleTooLong-location div {
  font-weight: normal;
  font-size: 0.8em
}

.wish-item-bottom-left .small-icon:hover {
  cursor: pointer;
}
</style>
